import React from 'react'
import Uuid from 'uuid/v4'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import '../css/style.scss'

const IndexPage = ({ data }) => (
  <div>
    <Header siteTitle="titleFromIndexPage" />
    {/* <Menu /> */}
    {data.allMarkdownRemark.edges.map(post => (
      <Layout
        key={Uuid()}
        tilNum={post.node.frontmatter.postid}
        optClass={'index-blog'}
      >
        <Link
          to={post.node.frontmatter.path}
          style={{
            color: '#333',
            width: '100%',
            display: 'block',
          }}
        >
          <h3>{post.node.frontmatter.title}</h3>
          <p>{post.node.frontmatter.desc}</p>
          {/*<p style={{ fontSize: 12, color: '#000', fontStyle: 'oblique' }}>
            {post.node.frontmatter.date}
        </p>*/}
          <img src={post.node.frontmatter.img} alt="blog-article" />
        </Link>
      </Layout>
    ))}
  </div>
)

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___postid] }
      limit: 1000
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            postid
            desc
            date
            img
          }
        }
      }
    }
  }
`

export default IndexPage
